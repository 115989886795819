//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import HorizontalTabsNav from '~/components/ReusableComponents/HorizontalTabsNav.vue';
import { LOGIN_METHOD } from '~/constants/authFlows.js';
const MobileInput = () => import('./MobileInput.vue');
const EmailInput = () => import('./EmailInput.vue');

export default {
    name: 'AuthMethodSelection',
    components: {
        HorizontalTabsNav,
        MobileInput,
        EmailInput
    },
    props: {
        loginSignupTabs: {
            type: Array,
            default: () => [],
            required: true
        },
        preselectedTab: {
            type: Object,
            default: () => {
                return {
                    id: 1,
                    value: 'login',
                    title: 'Login'
                };
            }
        },
        mainText: {
            type: String,
            default: ''
        },
        subText: {
            type: String,
            default: ''
        },
        isModal: {
            type: Boolean,
            default: false
        },
        isAcquisitionAuth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // ICONS
            yocketPrepLogo: `${process.env.CDN_ENDPOINT}/testprep/static/yocketPrep.png`,

            // CONSTANTS
            LOGIN_METHOD,

            // DATA
            selectedTab: null
        };
    },
    computed: {
        ...mapState('auth', {
            loginMethod: 'loginMethod',
            userCountry: 'userCountry'
        })
    },
    created() {
        this.selectedTab = this.preselectedTab ?? this.loginSignupTabs[0];
    },
    mounted() {
        this.loadGoogleAuthScript();
    },
    methods: {
        changeAuthType() {
            this.$emit('selectTab', this.selectedTab);
        },
        loadGoogleAuthScript() {
            const thisComponent = this;

            const script = document.createElement('script');
            script.type = 'text/javascript';

            script.addEventListener('load', (event) => {
                // eslint-disable-next-line no-undef
                google.accounts.id.initialize({
                    client_id: process.env.GOOGLE_OAUTH_CLIENT_ID,
                    callback: (response) => {
                        thisComponent.$emit('handleGoogleAuth', response);
                    }
                });
                // eslint-disable-next-line no-undef
                google.accounts.id.renderButton(
                    document.getElementById('buttonDiv'),
                    {
                        theme: 'outline',
                        shape: 'pill',
                        size: 'large',
                        width: 328
                    } // customization attributes
                );
                // eslint-disable-next-line no-undef
            });
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    }
};
